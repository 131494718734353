// import { v4 as uuidv4 } from "uuid";

import apiCall from "../api/apiCall";
import mongoCall from "../api/mongoCall";

// import { convertStringDate } from "./helpers/convertStringDate";
import { gridDataFormat } from "./helpers/gridDataFormat";
import { convertToISO } from "./helpers/convertToISO";
import { normalizeInput } from "../../helpers/formatNumber";

const GridAPI = {
    renewalAgreements: async function () {
        try {
            let result = await apiCall.get(`GetRenewalAgreements`);
            return result.data;
        } catch (error) {
            throw error;
        }
    },
    terminationNotices: async function () {
        try {
            let result = await apiCall.get(`GetTerminationAgreements`);
            return result.data;
        } catch (error) {
            throw error;
        }
    },
    payments: async function () {
        try {
            // console.time("GetAllPayments");
            let result = await apiCall.get(`GetAllPayments`);
            // console.timeEnd("GetAllPayments");
            // result.data.forEach((d) => {
            //     d.PaidDate = d.PaidDate ? new Date(d.PaidDate) : null;
            //     d.DueDate = d.DueDate ? new Date(d.DueDate) : null;
            // });

            return gridDataFormat("payments", result.data);
        } catch (error) {
            throw error;
        }
    },
    transactions: async function ({ prefix = false }) {
        try {
            // console.time("GetAllPaymentTransactions");
            let result = await apiCall.get(`GetAllPaymentTransactions`, { params: { prefix } });
            // console.timeEnd("GetAllPaymentTransactions");
            return gridDataFormat("transactions", result.data);
        } catch (error) {
            throw error;
        }
    },
    missingPayments: async function () {
        try {
            let result = await apiCall.get(`GetAllMissingPayments`);
            // result.data.forEach((d) => {
            //     d.PaymentExpectedDate = d.ExpectedBy;
            //     d.id = uuidv4();
            // });

            // result.data.forEach((d) => {
            //     d.Status = [
            //         { userName: "Cory Mutchler", comment: "Payments expected to come to ASM." },
            //         { userName: "Cory Mutchler", comment: "Sent an email about this" },
            //     ];
            // });

            return gridDataFormat("missingPayments", result.data);
        } catch (error) {
            throw error;
        }
    },
    // missingPayments: async function () {
    //     try {
    //         let result = await apiCall.get(`GetAllMissingPayments`);
    //         result.data.forEach((d) => {
    //             d.PaymentExpectedDate = d.PaymentExpected;
    //             d.id = uuidv4();
    //         });

    //         return gridDataFormat("missingPayments", result.data);
    //     } catch (error) {
    //         throw error;
    //     }
    // },
    agreements: async function ({ prefix = false }) {
        try {
            const result = await apiCall.get(`GetAllAgreements`, { params: { prefix } });
            result.data = result.data.map((d) => {
                const newQuickNote = d.QuickNote ? d.QuickNote.join("; ") : [];
                return {
                    ...d,
                    QuickNote: newQuickNote,
                };
            });
            return gridDataFormat("agreements", result.data);
        } catch (error) {
            throw error;
        }
    },
    documents: async function () {
        try {
            let result = await apiCall.get(`GetPaymentDocuments`);
            return gridDataFormat("documents", result.data);
        } catch (error) {
            throw error;
        }
    },
    services: async function () {
        try {
            let result = await apiCall.get(`GetAllServices`);

            return gridDataFormat("services", result.data);
        } catch (error) {
            throw error;
        }
    },
    serviceCategory: async function () {
        try {
            let result = await apiCall.get(`GetAllServiceCategories`);

            return gridDataFormat("serviceCategory", result.data);
        } catch (error) {
            throw error;
        }
    },
    properties: async function ({ prefix = false }) {
        try {
            let result = await apiCall.get(`GetAllProperties`, { params: { prefix } });

            if (result.data.length > 0) {
                result.data.forEach((r) => {
                    r.IsACHPayment = String(r.IsACHPayment);
                    if (r.Phone) {
                        r.Phone = normalizeInput(r.Phone);
                    }
                });
            }

            return gridDataFormat("properties", result.data);
        } catch (error) {
            throw error;
        }
    },

    contacts: async function () {
        try {
            let result = await apiCall.get(`GetAllContacts`);
            result.data.forEach((d) => {
                d.ContactId = d.id;
                if (d.mobilePhone) {
                    d.mobilePhone = normalizeInput(d.mobilePhone);
                }
                if (d.phone) {
                    d.phone = normalizeInput(d.phone);
                }
            });
            return gridDataFormat("contacts", result.data);
        } catch (error) {
            throw error;
        }
    },
    companies: async function () {
        try {
            let result = await apiCall.get(`GetAllClients`);

            result.data.forEach((d) => {
                let type = [];
                if (d.IsClientCompany) {
                    type.push("Client");
                }

                if (d.IsManagementCompany) {
                    type.push("Management Company");
                }

                if (d.IsOwnershipGroup) {
                    type.push("Ownership Group");
                }

                d.type = type.join(", ");
            });

            return gridDataFormat("companies", result.data);
        } catch (error) {
            throw error;
        }
    },
    businesses: async function () {
        try {
            let result = await apiCall.get(`GetAllClients`);
            result.data.forEach((d) => {
                let type = [];
                if (d.IsClientCompany) {
                    type.push("Client");
                }

                if (d.IsManagementCompany) {
                    type.push("Management Company");
                }

                if (d.IsOwnershipGroup) {
                    type.push("Ownership Group");
                }

                d.type = type.join(", ");

                if (d.MainPhone) {
                    d.MainPhone = normalizeInput(d.MainPhone);
                }
            });

            return gridDataFormat("businesses", result.data);
        } catch (error) {
            throw error;
        }
    },
    providers: async function () {
        try {
            let result = await apiCall.get(`GetAllProviders`);

            return gridDataFormat("providers", result.data);
        } catch (error) {
            throw error;
        }
    },
    tasks: async function (endDate, id) {
        try {
            console.time("GetAllTasks");

            let result = [];
            if (endDate && endDate !== "All") {
                const convertEndDate = convertToISO(endDate);
                if (id) {
                    result = await mongoCall.get(`tasks`, { params: { endDate: convertEndDate, id: id } });
                } else {
                    result = await mongoCall.get(`tasks`, { params: { endDate: convertEndDate } });
                }
            } else {
                result = await mongoCall.get(`tasks`);
            }

            console.timeEnd(`GetAllTasks`);

            return gridDataFormat("tasks", result.data);
        } catch (error) {
            throw error;
        }
    },
};

export { GridAPI };
